import React, { ReactNode } from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { Box, Hidden, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useWindowSize } from "mui-extensions/src/Hooks/useWindowSize";
import { TransitionStaticImage } from "StaticPages/Components/TransitionImage";
import { SkewAnimation } from "StaticPages/Components/Animations/SkewAnimation";
import { SectionTitle2 } from "StaticPages/Components/Commons/SectionTitle2";
import { SectionText1 } from "StaticPages/Components/Commons/SectionText1";
import { PageSummary } from "StaticPages/Components/Commons/PageSummary";

export default () => {
    const { t } = useTranslation();
    return <>
        <Helmet title={t("AI - AIについて ｜ VISUALIZ INC")} />
        <Layout>
            <div>
                <Main />
            </div>
        </Layout>
    </>;
};

const Main = () => {
    const [t] = useTranslation();
    return (
        <main>
            <Box >
                <PageSummary
                    title={t("プライバシーポリシー")}
                    subTitle={t("Privacy Policy")}
                    description={
                        <>
                        </>
                    }
                />
            </Box>

            <SectionText1>
                {t("株式会社VISUALIZ（以下，「当社」）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。")}
            </SectionText1>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第1条（個人情報）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第2条（個人情報の収集方法）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第3条（個人情報を収集・利用する目的）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第4条（利用目的の変更）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第5条（個人情報の第三者提供）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第6条（個人情報の開示）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第7条（個人情報の訂正および削除）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第8条（個人情報の利用停止等）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第9条（プライバシーポリシーの変更）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>

            <Box
                p={{ xs: 3, sm: 4, md: 6 }}
            >
                <SectionTitle2
                    title={t("第9条（プライバシ第10条（お問い合わせ窓口）ーポリシーの変更）")}
                />
                <Box
                    my={{ xs: 3, sm: 4, md: 6 }}
                >
                    <SectionText1>
                        {t("当社が個人情報を収集・利用する目的は，以下のとおりです。,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。")}
                    </SectionText1>
                </Box>
            </Box>
        </main >
    );
};
